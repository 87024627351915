import { Trans, useTranslation } from 'react-i18next'
import {
  Avatar,
  Box,
  Card,
  Container, Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar, ListItemButton,
  ListItemText,
  Stack,
  Typography
}                                from '@mui/material'
import { useApi }                from '../utils/api'
import React, { useEffect, useState }                from 'react'
import { fDateFromTo, fDateTime, fOperator, fPrice } from '../utils/textutils'
import Page                                          from '../ui/components/Page'
import { BaseBreadcrumbs } from '../ui/components/BaseBreadcrumbs'
import { useParams, Link as RouterLink } from 'react-router-dom'
import Iconify from '../ui/components/Iconify'

export default function MessagesPage () {

  const { messageId } = useParams()
  const { t } = useTranslation()
  const api = useApi()
  const [message, setMessage] = useState(null)

  useEffect(() => {
    api.message.detail(messageId).then(msg => {
      setMessage(msg)
    }).catch(e => {})
  }, [messageId])

  return (<Page title={`#${messageId} ${t('Message')}`}>
    <Container maxWidth={'xl'}>
      <BaseBreadcrumbs key={'breadcrumb-messages'}
                       title={`${t('Message')} #${messageId}`}
                       items={[
                         { href: '/messages', title: t('Messages') }
                       ]}/>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContentWidget title={t('Content')} content={message?.content ?? ''} />
        </Grid>
        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <DataRow title={t('Tel. From')} content={message?.telFrom} icon={'material-symbols:call-made'}/>
            <Divider component={'li'} />
            <DataRow title={t('Tel. To')} content={message?.telTo} icon={'material-symbols:call-received'}/>
            <Divider component={'li'} />
            {message?.received && <>
              <DataRow title={t('Delivery status')} content={t(`deliverystatus-incoming`)} icon={'material-symbols:call-received'} />
              <Divider component={'li'} />
            </>}
            {message?.sent && <>
              <DataRow title={t('Delivery status')} content={t(`deliverystatus-${message?.deliveryStatus}`)} icon={'material-symbols:check'} />
              <Divider component={'li'} />
            </>}
            <DataRow title={t('Operator')} content={fOperator(message?.operator)} icon={'material-symbols:deskphone'} />
            {message?.parentId && <>
              <Divider component={'li'} />
              <ClickableDataRow
                icon={'material-symbols:sms'}
                title={t('SMS is answer for')}
                content={<Trans i18nKey={'ShowUserSms'} values={{ id: message.parentId }} />}
                href={`/messages/${message.parentId}`}
              />
            </>}
            {message?.ticket && <>
              <Divider component={'li'} />
              <ClickableDataRow
                icon={'material-symbols:confirmation-number-outline'}
                title={<Trans i18nKey={'TicketNo'} values={{ id: message.ticket.id }} />}
                content={<React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }} component="span" variant="body2" color="text.disabled">
                    {t('ValidInTime')}:
                  </Typography>
                  &nbsp;{`${fDateFromTo(message.ticket.validFrom, message.ticket.validTo)}, `}
                  <Typography
                    sx={{ display: 'inline' }} component="span" variant="body2" color="text.disabled">
                    {t('Licence')}:
                  </Typography>
                  &nbsp;{`${message.ticket.licence}, `}
                  <Typography
                    sx={{ display: 'inline' }} component="span" variant="body2" color="text.disabled">
                    {t('Price')}:
                  </Typography>
                  &nbsp;{fPrice(message.ticket.price)}
                </React.Fragment>}
                href={`/tickets/${message.ticket.id}`}
              />
            </>}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <DataRow title={t('Foreign ID')} content={message?.foreignId ?? t('Not set')} />
            <Divider component={'li'} />
            <DataRow title={t('Created')} content={message?.created ? fDateTime(message.created) : t('Not set')} />
            <Divider component={'li'} />
            <DataRow title={t('Received')} content={message?.received ? fDateTime(message.received) : t('Not set')} />
            <Divider component={'li'} />
            <DataRow title={t('Sent')} content={message?.sent ? fDateTime(message.sent) : t('Not set')} />
          </List>
        </Grid>
      </Grid>
    </Container>
  </Page>)
}

function ContentWidget( { title, content, sx, other } ) {
  return <Card
    component={Stack}
    sx={{
      px: 3,
      py: 5,
      borderRadius: 2,
      ...sx,
    }}
    {...other}
  >
    <Typography variant="subtitle2">{title}</Typography>
    <Typography variant="body2" sx={{ color: 'text.disabled' }}>
      {content}
    </Typography>
  </Card>
}

function DataRow({title, content, icon}) {
  return (<ListItem>
    {icon && <ListItemAvatar>
      <Avatar>
        <Iconify icon={icon} />
      </Avatar>
    </ListItemAvatar>}
    <ListItemText primary={content} secondary={title} />
  </ListItem>)
}
function ClickableDataRow({title, content, href, icon}) {
  return (<ListItem>
    <ListItemButton sx={{ p: 0 }} component={RouterLink} to={href}>
      {icon && <ListItemAvatar>
        <Avatar>
          <Iconify icon={icon} />
        </Avatar>
      </ListItemAvatar>}
      <ListItemText primary={content} secondary={title} />
    </ListItemButton>
  </ListItem>)
}
